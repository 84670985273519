<template>
  <nav :class="{
    navbar: ($route.path === '/' || $route.path === '/about'),
    'other-navbar': ($route.path !== '/' && $route.path !== '/about'),
  }" class="min-w-1400">
    <div :class="{
      secondBox: ($route.path === '/' || $route.path === '/about'),
      'other-secondBox': ($route.path !== '/' && $route.path !== '/about'),
    }" class="max-w-1400">
      <div class="logo">
        <!-- <span class="title">GADZILLA COMPANY LIMITED</span> -->
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">หน้าแรก</span>
          </router-link>
        </li>
        <!-- <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">เกี่ยวกับผลิตภัณฑ์</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">ศูนย์ช่วยเหลือ</span>
          </router-link>
        </li> -->
        <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <!-- <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">ข้อตกลงความเป็นส่วนตัว</span>
          </router-link> -->
          <a href="https://win.awinaccounting.com/A8w9c7c3/c9P0e4ysxy.html?a4w0c9g=awinaccount_ios"
            class="nav-link4" target="_blank">
            <span class="routeText4">ข้อตกลงความเป็นส่วนตัว</span>
          </a>
        </li>
      </ul>
    </div>
    <div style="height: 130px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  /* position: sticky;   */
  top: 0;
  /* 位于页面顶部 */
  z-index: 1000;
  /* 确保导航栏在其他元素上方 */
  height: 100px;
  position: absolute;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100px;
  padding: 0 30px;
  background-color: "";
  position: absolute;
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
}

.title {
  /* width: 520px; */
  height: 24px;
  font-size: 18px;
  font-family: SF Pro-Black Italic;
  font-weight: 600;
  color: #39383D;
  line-height: 23px;
  padding-left: 17px;
  /* text-transform: uppercase; */
  /* border: 1px solid red; */
  width: 550px;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #FFFFFF;
  line-height: 29px;
  /* text-transform: uppercase; */
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
  text-align: center;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  padding: 8px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 45px;
  padding-left: 4px;
  padding-right: 4px;
}

.Li1 {
  width: 140px;
}

.Li2 {
  width: 220px;
}

.Li3 {
  width: 180px;
}

.Li4 {
  width: 250px;
}

/* .Li5 {
  width: 250px;
} */
/* 这个如果给margin-right底部可能会出现边距的问题 */
/* .Li4 {
  width: 250px;
  
} */

.active-link {
  /* background: linear-gradient(45deg, #F59120, #F95C40); */
  /* background-color: #FFFFFF;
  border-color: #FFFFFF; */
  /* 边框样式 */
  border: 1px solid #ffffff; 
  border-radius: 12px;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;
  color: #FFFFFF;
}

.other-navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  /* position: sticky;   */
  top: 0;
  /* 位于页面顶部 */
  height: 100px;
}

.other-secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100px;
  padding: 0 30px;
  /* background-image: url("@/page/gadzillacompany/components/img/NavBg.png"); */
  /* background-repeat: no-repeat;
  background-size: 100% 100%; */
  background-color: #FBFBFB;
}

.title {
  /* border: 1px solid red; */
  width: 350px;
}

.secondBox,
.other-secondBox {
  padding: 0 250px 0 120px;
}
</style>